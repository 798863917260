import * as react from 'react'
import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

function App() {
  //テーブルの見出し
  const columns = [
    {
      field: 'アイコン', headerName: '', width: 100, renderCell: (params) =>
        <Grid container justifyContent='center' alignItems='center'>
          <ViewWeekIcon sx={{ color: '#ccc', fontSize: 40 }} />
        </Grid>
    },
    { field: 'name', headerName: 'name', width: 200 },
    { field: 'email', headerName: 'email', width: 200 },
    { field: 'tel', headerName: 'tel', width: 300 },
    { field: 'address', headerName: 'address', width: 300 },
    { field: 'strength', headerName: 'strength', width: 200 },
  ];

  //テーブルの値
  const row = [
    { id: 1, name: "A", email: "sample1@gmail.com", tel: "090-2222-1111", address: "森野ホテル", strength: 100 },
    { id: 2, name: "シャンクス", email: "sample2@gmail.com", tel: "080-3333-2222", address: "音楽島エレジア", strength: 1000000 },
    { id: 3, name: "すずめ", email: "sample3@gmail.com", tel: "090-3737-4444", address: "宮崎県日南市", strength: 100 },
    { id: 4, name: "アーニャ", email: "sample4@gmail.com", tel: "080-7777-2222", address: "イーデン校", strength: 100000000 },
    { id: 5, name: "宿儺", email: "sample5@gmail.com", tel: "090-4433-2211", address: "呪術高専", strength: 10000 },
  ];  
  return (
    <Box sx={{ height: 500, width:'80%'}}>
      <DataGrid
        rows={row}
        columns={columns}
        sx={{ height: "500px", fontSize: 15, border: "none" }}
        initialState={{
          pagination:{
            paginationModel: {
              pageSize: 5,
            }
          }
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default App;